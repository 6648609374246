/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../../layouts"
import Header from "../../components/header"
import PageWithSidebar from "../../components/page-with-sidebar"
import NewStickyResponsiveSidebar from "../../components/sidebar/new-sticky-responsive-sidebar"
import { getCurrentDocsSection } from "../../utils/sidebar/item-list.js"
import {
  SubsectionList,
  LinkList,
  findCurrentDocsPage,
  PageHeader,
} from "../../components/docs/docs-type-landing-section.js"
import allDocs from "../../components/sidebar/all-docs-sidebar.yaml"
import Container from "../../components/container"
import FooterLinks from "../../components/shared/footer-links.js"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import SEO from "../../components/seo"

function DocsSection({
  location,
  pageContext: {
    section: { to },
  },
}) {
  const currentPage = findCurrentDocsPage(allDocs[0], to)
  const currentDocumentationType = getCurrentDocsSection(location.pathname)

  return (
    <Layout>
      <SEO
        title={currentPage.label}
        description={
          currentPage.shortDescription || currentPage.longDescription
        }
      />
      <Header
        applyTopPadding={false}
        isFullWidth
        isFixed
        docType={currentDocumentationType}
      />
      <PageWithSidebar
        location={location}
        SidebarComponent={NewStickyResponsiveSidebar}
      >
        <SkipNavTarget />
        <PageHeader
          emphasizedColor={currentPage.emphasizedColor}
          titleText={currentPage.label}
          description={
            currentPage.longDescription || currentPage.shortDescription
          }
          location={location}
        />
        <Container>
          {currentPage.subSections ? (
            <SubsectionList
              currentPage={currentPage}
              advanced={currentPage.subItems.find(
                item => item?.itemType === `additional`
              )}
            />
          ) : (
            <LinkList
              items={currentPage.subItems.filter(item => !item.itemType)} // exclude both overview & additional
              advanced={currentPage.subItems.find(
                item => item?.itemType === `additional`
              )}
            />
          )}
          <FooterLinks />
        </Container>
      </PageWithSidebar>
    </Layout>
  )
}

export default DocsSection
